import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { __ } from '../../i18n';
import { setData } from './helpers/setData';
import { urlToPromise } from './helpers/getUrlFromPromise';

export async function momentsDownload(medias, fileName, toggleDownloadingState, popupError, app) {
  try {
    toggleDownloadingState(true);
    const data = await setData(medias);
    const zip = new JSZip();
    const imgs = zip.folder(__('Images'));
    const vid = zip.folder(__('Videos'));
    let hasImages = false;
    let hasVideos = false;

    if (app && app.env === 'production') {
      await data.forEach((media) => {
        const { mimetype, url } = media;

        if (mimetype.includes('video')) {
          vid.file(url.replace(/.*\//g, ''), urlToPromise(url), { binary: true });
          hasVideos = true;
        } else {
          imgs.file(url.replace(/.*\//g, ''), urlToPromise(url), { binary: true });
          hasImages = true;
        }
      });
    } else {
      await data.forEach((media) => {
        const { url } = media;
        const url2 = console.info(url.replace('https://', 'http://'));
        imgs.file(url2, urlToPromise(url2), { binary: true });
        hasImages = true;
      });
    }

    if (!hasImages) { await zip.remove(__('Images')); }

    if (!hasVideos) { await zip.remove(__('Videos')); }

    await zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, fileName + '.zip');
    });
  } catch (err) {
    console.log(`An error occurred: ${err}`);
    popupError(__('An error has occurred.'));
  } finally {
    toggleDownloadingState(false);
  }
}

export async function videoDownload(media, fileName, toggleDownloadState, popupError) {
  try {
    toggleDownloadState(true);
    const { uri } = media;
    const zip = new JSZip();

    zip.file(uri.replace(/.*\//g, ''), urlToPromise(uri), { binary: true });

    await zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, fileName + '.zip');
    });
  } catch (err) {
    console.log(`An error occurred: ${err}`);
    popupError(__('An error has occurred.'));
  } finally {
    toggleDownloadState(false);
  }
}


export async function imageDownload(media, fileName, toggleDownloadState, popupError, app) {
  try {
    toggleDownloadState(true);
    const { uri } = media;
    const zip = new JSZip();

    if (app && app.env === 'production') {
      zip.file(uri, urlToPromise(uri), { binary: true });
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, fileName + '.zip');
      });
    } else {
      const url = console.info(uri.replace('https://', 'http://'));
      zip.file(url, urlToPromise(url), { binary: true });
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, fileName + '.zip');
      });
    }
  } catch (err) {
    console.log(`An error occurred: ${err}`);
    popupError(__('An error has occurred.'));
  } finally {
    toggleDownloadState(false);
  }
}
