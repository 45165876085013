import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Icon, Segment, Dropdown, Header, Popup } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Slider from 'react-slick';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import Avatar from '../../components/Avatar';
import Modal from '../../components/Modal';
import ReactionItem from '../../components/ReactionItem';

import MomentStatus from './Status';

import * as utils from '../../utils';
import { __ } from '../../i18n';
import VideoPlayer from '../../components/VideoPlayer';

const settings = {
  dots: true,
  dotsClass: 'slick-dots slick-dots-clsp'
};

@inject('store', 'client')
@graphql(gql`query MomentNodeQuery (
  $entityId: ID!,
  $momentId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        moment: message (id: $momentId ) {
          id: dbId
          created
          content
          type
          entity {
            id: dbId
            organizationId
            fullname
            picture {
              uri
              id: dbId
              key
            }
          }
          reaction (entityId: $entityId) {
            type
          }
          reactions {
            totalCount
          }
          medias {
            nodes {
              id: dbId
              mimetype
              uri (size:"w1280")
              origName
            }
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      entityId: ownProps.params.entity_id,
      momentId: ownProps.params.moment_id
    }
  })
})
@observer
export default class MomentNode extends React.Component {
  componentDidMount() {
    const { params, store, client } = this.props;
    const userHasEntity = params.entity_id && store.currentUser.entities &&
      (store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === params.entity_id).length > 0);

    if (!userHasEntity) return;

    client.query({
      query: gql`query MomentNodeUpdateQuery (
        $entityId: ID!,
        $momentId: ID!,
        $deleteNotifications: Boolean,
        $updateStatus: RecipientStatus,
        $updateAccessed: Boolean
      ) {
          node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
            ... on Entity {
              id: dbId
              message (id: $momentId, deleteNotifications: $deleteNotifications, updateAccessed: $updateAccessed, updateStatus: $updateStatus) {
                id: dbId
              }
            }
          }
        }`,
      fetchPolicy: 'network-only',
      variables: {
        entityId: params.entity_id,
        momentId: params.moment_id,
        deleteNotifications: true,
        updateStatus: 'READ',
        updateAccessed: true
      }
    });
  }

  onClose = () => {
    const { store, router } = this.props;

    if (store.history.length > 1) {
      router.goBack();
    } else {
      router.push(`/entities/${store.previous.params.entity_id}/moments`);
    }
  }

  onToggleReaction = (reaction) => {
    const { data, createReaction, deleteReaction, params } = this.props;

    const { moment } = data.node;

    if (!(moment.reaction && moment.reaction.type)) {
      createReaction({
        variables: {
          createReactionMutation: {
            messageId: moment.id,
            entityId: params.entity_id,
            type: reaction
          }
        }
      });
    } else {
      deleteReaction({
        variables: {
          deleteReactionMutation: {
            messageId: moment.id,
            entityId: params.entity_id
          }
        }
      });
    }
  }

  render() {
    const { data, params, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { moment } = data.node;
    const { entity, medias, created, type, deleted, reactions } = moment;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const date = utils.simpleDate(created, true, null, lang);
    const baseLink = `/entities/${params.entity_id}`;
    const isAdmin = (store.currentUser.isMaster || (store.currentEntity.type === 'ADMIN' && store.currentEntity.organization.id === entity.organizationId));
    const canReact = store.currentEntity.id == params.entity_id;

    if (!medias.nodes.length) return null;

    return (
      <Modal id="MomentNode" open size="tiny" closeIcon={'times close'} onClose={() => this.onClose()}>
        <Modal.Header style={{ padding: '8px 16px' }}>
          <Header as="h5" className="entityItem" style={{ display: 'inline-flex', alignItems: 'center' }} title={entity.fullname} data-id={entity.id}>
            <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} style={{ height: '2.5em' }} />
            <Header.Content>
              {
                entity.fullname ?
                  (
                    entity.fullname.length > 18 ?
                      <Popup
                        trigger={!isAdmin ? <span>{entity.fullname.substring(0, 18).trim()}...</span> :
                          <Link className="bold defaultClspColor" to={`/entities/${entity.id}`}>{entity.fullname.substring(0, 18).trim()}...</Link>}
                        content={entity.fullname}
                      />
                      :
                      !isAdmin ? <span>{entity.fullname}</span> : <Link className="bold defaultClspColor" to={`/entities/${entity.id}`}>{entity.fullname}</Link>
                  )
                  :
                  null
              }
              <Header.Subheader style={{ color: '#9696a0' }}>
                {date}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content style={{ padding: 0 }}>
          <Segment.Group style={{ width: '100%', border: 0 }}>
            <Segment style={{ padding: 0 }} className="clsp-square">
              {
                medias.nodes.length > 1 ?
                  <Slider {...settings} className="clsp-square-content">
                    {
                      medias.nodes.map((media, key) => (media.mimetype.includes('video') ?
                        <div className="clsp-video">
                          <VideoPlayer oldPlayer loop src={media.uri} name={media.origName} thumbnail={media.thumbnail} />
                        </div>
                        :
                        <div
                          key={key}
                          className="momentImage"
                          style={{
                            width: '100%',
                            background: 'url(' + media.uri + ')'
                          }}
                        />))
                    }
                  </Slider>
                  :
                  (medias.nodes[0].mimetype.includes('video') ?
                    <div className="clsp-square-content" style={{ display: 'flex' }}>
                      <div className="clsp-video" style={{ width: '100%' }}>
                        <VideoPlayer oldPlayer loop src={medias.nodes[0].uri} name={medias.nodes[0].origName} thumbnail={medias.nodes[0].thumbnail} />
                      </div>
                    </div>
                    :
                    <div
                      className="clsp-square-content momentImage"
                      style={{
                        background: 'url(' + medias.nodes[0].uri + ')'
                      }}
                    />)
              }
            </Segment>
            <Segment>
              <div className="horizontallySpacedItems">
                <Header as="h5" style={{ margin: 0 }}>
                  <ReactionItem message={moment} canReact={canReact} params={params} />
                  {
                    !reactions.totalCount ? null :
                      <Header.Content
                        style={{ color: '#868e96' }}
                        onClick={(type === 'SENT' || params.organization_id) ? () => store.appends.push(<MomentStatus moment={moment} />) : undefined}
                        className={(type === 'SENT' || params.organization_id) ? 'pointer' : ''}
                      >
                        {reactions.totalCount > 1 ? __('%s likes', reactions.totalCount) : __('1 like')}
                      </Header.Content>
                  }
                </Header>
                {
                  (type === 'SENT' || params.organization_id) && !deleted ?
                    <Dropdown icon={null} trigger={<Icon data-action="open-moment-options" name="ellipsis h" style={{ color: '#9696a0' }} />}>
                      <Dropdown.Menu className="bold">
                        {
                          params.organization_id ? null :
                            <Dropdown.Item
                              as={Link}
                              to={`/entities/${params.entity_id}/moments/${moment.id}/edit`}
                            >
                              {__('Edit')}
                            </Dropdown.Item>
                        }
                        <Dropdown.Item
                          as={Link}
                          to={baseLink + `/moments/${moment.id}/delete`}
                        >
                          {__('Delete')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    null
                }
              </div>
              {!!moment.content && <div className="content clsp-content" style={{ marginTop: 12 }}>
                {moment.content.split(/(#[A-Za-z0-9À-ÿ]+)/g).map((content) => {
                  if (content.match(/#[A-Za-z0-9À-ÿ]+/g)) {
                    return <Link to={baseLink + `/moments?search=${encodeURIComponent(content)}${deleted ? '&folder=DELETED' : ''}`}>{content}</Link>;
                  }
                  return content;
                })}
              </div>}
            </Segment>
          </Segment.Group>
        </Modal.Content>
      </Modal>
    );
  }
}
