/* eslint-disable prettier/prettier */
export async function setData(medias) {
  // Gets 'medias' array from Item.js and returns an object array containing the right url and type of the medias!
  return await medias.nodes.map((media) => {
    const { mimetype } = media;
    let { uri } = media;
    uri = mimetype.includes('image')
      ? uri.replace(
        'https://d391o0jis58pqh.cloudfront.net/w1280',
        'https://s3-us-west-2.amazonaws.com',
      )
      : uri;
    uri = mimetype.includes('image')
      ? uri.replace(
        'https://images.classapp.com.br/w1280',
        'https://s3-us-west-2.amazonaws.com',
      )
      : uri;

    return { url: uri, mimetype };
  });
}
