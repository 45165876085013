/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Icon, Image, Popup, Dropdown, Container } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import VideoThumbnail from 'react-video-thumbnail';
import { uniqueId } from 'lodash';
import 'react-html5video/dist/styles.css';


import Form from '../../components/ui/Form';
import BetaButton from '../../components/ui/Button';

import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import Avatar from '../../components/Avatar';
import ImageCropper from '../../components/ImageCropper';
import FeaturesModal from '../../components/FeaturesModal';

import EntityRecipients from '../Entity/ui/Recipients';

import MessageRecipients from '../Message/Recipients';


import { __ } from '../../i18n';
import * as utils from '../../utils';
import VideoPlayer from '../../components/VideoPlayer';

const MEDIA_LIMIT = 25;
const ATTACHMENT_SIZE_MB = 25;
const BYTES_IN_ONE_MB = 1e+6;

const uploadMediaMutation = `mutation UploadMedia ($entityId: Int!) {
  uploadMedia (input: {entityId: $entityId, transcode: true}) {
    media {
      id: dbId
      filename
      uri
      thumbnail
    }
  }
}`;

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '14px',
    paddingRight: '14px'
  },
};

@inject('store', 'api') @observer
export default class MomentForm extends Form {
  defaultValues = {
    recipients: [],
    groups: [],
    content: '',
    medias: []
  }

  rules = {
    medias: 'required',
    groups: 'required',
    medias: `maxLength:${MEDIA_LIMIT}`
  }

  handleSelectedItems = (data) => {
    const { values } = this.state;
    const selectedAll = data.selected;

    values.groups = data.groups.nodes.filter(group => group.selected);
    values.allGroups = data.groups.nodes;

    this.setState({ values, selectedAll });
  }

  openPaywall = () => {
    this.props.store.appends.push(<FeaturesModal feature={'VIDEO_MOMENTS'} />);
  }

  onFileDrop = (accepted, rejected) => {
    const { store } = this.props;

    const acceptedVideos = accepted.filter(file => file.type.indexOf('video') !== -1);
    const acceptedImages = accepted.filter(file => file.type.indexOf('video') === -1);

    if (store.entity && store.entity.organization && !store.entity.organization.features.videos && acceptedVideos.length > 0) {
      this.openPaywall();
      return;
    }

    if (rejected.length > 0) {
      if (rejected.find(file => file.size > ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB)) {
        store.snackbar = { active: true, message: __('File too big, maximum size is %s MB.', ATTACHMENT_SIZE_MB), success: false, dismissAfter: 5000 };
      } else {
        store.snackbar = { active: true, message: __('File format not accepted, please provide an image or video.'), success: false };
      }
    }

    if (accepted.length + this.state.values.medias.length > MEDIA_LIMIT) {
      accepted = accepted.splice(0, MEDIA_LIMIT - this.state.values.medias.length);
      store.snackbar = { active: true, message: __('You can not have more than %s files in a moment, exceeding files will be ignored', MEDIA_LIMIT), success: false, dismissAfter: 7000 };
    }

    let medias = this.state.values.medias;
    let index = medias.length - 1;

    accepted = accepted.map((file, i) => {
      file.index = index + i;
      file.uniqueId = uniqueId();

      return file;
    });

    if (acceptedImages.length > 0) {
      this.props.store.appends.push(<ImageCropper
        medias={acceptedImages}
        onSubmit={(cropped) => {
          this.props.store.appends.pop();

          medias = [...medias, ...acceptedVideos, ...cropped];
          medias.sort((a, b) => (a.index < b.index ? -1 : (a.index > b.index ? 1 : 0)));

          this.setState({ values: { ...this.state.values, medias } });
        }}
        onClose={() => this.props.store.appends.pop()}
      />);
    } else if (accepted.length > 0) {
      medias = [...medias, ...accepted];
      this.setState({ values: { ...this.state.values, medias } });
    }
  }

  uploadFiles = async () => {
    let medias = this.state.values.medias;
    let requests = [];

    medias.forEach((file, index) => {
      medias[index].loading = true;
      requests.push(this.uploadFile(file, index));
    });

    this.setState({ values: { ...this.state.values, medias } });

    await Promise.all(requests);
  }

  onUploadProgress = (progressEvent, index) => {
    const { loaded, total } = progressEvent;
    const medias = this.state.values.medias;

    medias[index].progress = Math.round((loaded * 100) / total);

    this.setState({ values: { ...this.state.values, medias } });
  }

  onGenerateThumbnail = (thumbnail, index) => {
    const medias = this.state.values.medias;

    medias[index].thumbnail = thumbnail;

    this.setState({ values: { ...this.state.values, medias } });
  }

  uploadFile = (file, index) => this.props.api.upload('media', {
    file: file.crop ? file.crop.blob : file,
    query: uploadMediaMutation,
    variables: `{"entityId": ${this.props.entity.id}}`
  }, progressEvent => this.onUploadProgress(progressEvent, index))
    .then((data) => {
      file.loading = false;
      file.response = data.data.uploadMedia.media;

      const medias = this.state.values.medias;

      medias[index] = file;

      this.setState({ values: { ...this.state.values, medias } });
    }).catch(() => {
      file.loading = false;
      file.failed = true;

      const medias = this.state.values.medias;

      medias[index] = file;

      this.setState({ values: { ...this.state.values, medias } });
    });

  openEntityRecipientForm = selected =>
    this.props.store.appends.push(
      <BetaModal
        id={'recipientAttachmentForm'}
        size="tiny"
        fullScreen={this.isMobile()}
        marginBottom
        spaceFooter
        actions={!this.isMobile()}
        portalHeader
        scrolling
        content={
          <EntityRecipients
            modal
            disableEntitySelect
            groupsOnly
            isMobile={this.isMobile()}
            cancelButton={{ isActionButtom: true }}
            selectedOnly={selected}
            onSubmit={(data) => { this.props.store.appends.pop(); this.handleSelectedItems(data); }}
            submitButton={{ isActionButtom: true }}
            onCancel={() => this.props.store.appends.pop()}
            organization={this.props.organization}
            entity={this.props.entity}
            fetchOnSubmit={!this.state.fetchedAll}
          />
        }
      />
    );


  onSubmit = async (e) => {
    await this.uploadFiles();
    this.handleSubmit(e);
  }

  beforeSubmit = (values) => {
    //Get filename from medias
    if (values.medias.length > 0) {
      values.medias = values.medias.filter(media => media.response).map(media => ({
        filename: media.response.filename,
        type: utils.getMediaType(media.type, media.name),
        uri: media.response.uri,
        origName: media.name
      }));
    }

    values.groups = values.groups.map(group => group.id);

    return values;
  }

  handleProps = () => {
    const { values } = this.state;
    const newProps = { ...this.props, modal: true };

    if ((!values.groups.length || !values.medias.length || values.medias.length > MEDIA_LIMIT || values.medias.filter(media => media.loading).length) && !this.props.edit) {
      newProps.submitButton = {
        ...newProps.submitButton,
        disabled: true,
      };
    }

    if (values.medias.filter(media => media.loading).length) {
      newProps.submitButton = {
        ...newProps.submitButton,
        loading: true,
      };
    }

    return newProps;
  }

  onRemoveGroup = (group) => {
    const { values } = this.state;
    const newAllGroups = values.allGroups.map((g) => { if (g.id === group.id) { return ({ ...g, selected: false }); } return g; });
    const data = { groups: { nodes: newAllGroups } };
    this.handleSelectedItems(data);
  }

  renderManyRecipients = () => {
    const { errors, values } = this.state;

    return (
      <div
        style={{ display: 'flex', alignItems: this.isMobile() ? 'flex-start' : 'center', flexFlow: this.isMobile() ? 'column' : 'row nowrap', justifyContent: 'space-between' }}
        error={errors && (errors.groups || errors.recipients) && !(values.recipients.length || values.groups.length)}
      >
        <div style={{ width: '156px' }}>
          <BetaButton
            text={__('Recipients')}
            icon={{ name: 'plus', style: { fontSize: '16px' } }}
            style={{ display: 'flex', flexWrap: 'nowrap', padding: '0.9rem 1.143rem', fontSize: '16px', margin: '0' }}
            transparent
            data-action="open-entity-recipient-form"
            onClick={() => this.openEntityRecipientForm()}
            round
          />
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: this.isMobile() ? '0px' : '6px' }}>
          <MessageRecipients
            handleSelectedItems={this.handleSelectedItems}
            placeholder={errors && (errors.groups || errors.recipients) ?
              __('Search and add at least one recipient') :
              __('Search for a group')
            }
            dropDownTags={'round-corners'}
            hideSearch={this.isMobile()}
            hidePlaceholder={this.isMobile()}
            organization={this.props.organization}
            entity={this.props.entity}
            recipients={this.props.recipients}
            style={{ boxShadow: 'none', fontSize: '16px', border: '1px solid rgba(0, 0, 0, 0.16)' }}
            wrapType
            groupsOnly
            round
            roundCorners
          />
        </div>
      </div>
    );
  }

  renderMediaPreview = media => this.props.store.appends.push(<Modal
    size="small"
    closeIcon="times close"
    onClose={() => this.props.store.appends.pop()}
  >
    <Modal.Content image>
      <Container textAlign="center">
        {
          media.type.includes('image') ?
            <Image src={media.crop.dataUrl} wrapped />
            :
            <div className="clsp-video" style={{ height: 320 }}>
              <VideoPlayer oldPlayer src={media.preview} name={media.name} thumbnail={media.thumbnail} />
            </div>
        }
      </Container>
    </Modal.Content>
  </Modal>);

  renderMedias = () => this.state.values.medias.map((media, key) => {
    if (media.loading) {
      return (
        <span key={key} style={{ marginRight: '12px', position: 'relative' }}>
          <div
            style={{
              height: '180px',
              width: '200px',
              background: 'url(' + (media.thumbnail || media.crop.dataUrl) + ')',
              backgroundSize: ' cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '4px',
              opacity: '.24',
            }}
            className={media.thumbnail ? '' : 'momentImage'}
          />
          <div
            style={{
              height: '180px',
              width: `${100 - media.progress}%`,
              borderRadius: '4px',
              backgroundColor: 'black',
              opacity: '.6',
              position: 'absolute',
              top: 0,
              right: 0
            }}
          />
        </span>
      );
    }

    let thumb = (<div
      style={{
        height: '180px',
        width: '200px',
        background: 'url(' + (media.thumbnail || (media.crop && media.crop.dataUrl)) + ')',
        backgroundSize: ' cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '4px'
      }}
      className="momentImage"
    />);

    if (!media.thumbnail && media.type.includes('video')) {
      thumb = (<div
        style={{
          height: '180px',
          width: '200px',
          borderRadius: '4px',
          opacity: '.24',
        }}
      >
        <VideoThumbnail
          videoUrl={media.preview}
          snapshotAtTime={1}
          thumbnailHandler={thumbnail => this.onGenerateThumbnail(thumbnail, key)}
        />
      </div>);
    }

    return (
      <span key={media.uniqueId} className="hoverReveal" style={{ marginRight: '12px' }}>
        {thumb}
        <div
          className="wordWrapped"
          style={{
            height: '180px',
            width: '100%',
            padding: '12px',
            borderRadius: '4px',
            color: '#ffffff'
          }}
        >
          {
            media.name.length > 36 ?
              <Popup
                trigger={<p>{media.name.replace(/^(.{36}).+/, '$1...')}</p>}
                content={<span>{media.name}</span>}
              />
              :
              <p>{media.name}</p>
          }
          <span style={{ position: 'absolute', bottom: '16px' }}>
            <Icon name={media.type.includes('image') ? 'image' : 'video'} /> {utils.formatBytes(media.size, 1)}
          </span>
          <span
            style={{
              position: 'absolute',
              right: '12px',
              bottom: '16px'
            }}
          >
            <Dropdown icon={null} trigger={<Icon name="ellipsis v" />} upward>
              <Dropdown.Menu className="bold">
                {!!media.type.includes('image') && <Dropdown.Item
                  data-action="adjust"
                  onClick={() => this.props.store.appends.push(<ImageCropper
                    medias={[media]}
                    onSubmit={(cropped) => {
                      this.props.store.appends.pop();

                      const medias = this.state.values.medias;
                      cropped[0].thumbnail = null;
                      medias[key] = cropped[0];

                      this.setState({ values: { ...this.state.values, medias } });
                    }}
                    onClose={() => this.props.store.appends.pop()}
                  />)}
                >
                  {__('Adjust')}
                </Dropdown.Item>}
                <Dropdown.Item
                  data-action="preview"
                  onClick={() => this.renderMediaPreview(media)} style={{ cursor: 'pointer' }}
                >
                  {__('Preview')}
                </Dropdown.Item>
                <Dropdown.Item
                  data-action="delete"
                  onClick={() => {
                    let medias = [
                      media,
                      ...this.state.values.medias.slice(0, key),
                      ...this.state.values.medias.slice(key + 1)
                    ];

                    medias = medias.map((file, i) => {
                      file.index = i;

                      return file;
                    });

                    this.setState({ values: { ...this.state.values, medias } });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {__('Make it the first')}
                </Dropdown.Item>
                <Dropdown.Item
                  data-action="delete"
                  onClick={() => {
                    this.popValue('medias', key, () => {
                      let medias = this.state.values.medias;

                      medias = medias.map((file, i) => {
                        file.index = i;

                        return file;
                      });

                      this.setState({ values: { ...this.state.values, medias } });
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {__('Remove')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </span>
    );
  });

  renderFromField = () => {
    const { title } = this.props;
    return (<div style={this.isMobile() ? {} : { marginTop: '-10px', marginBottom: '10px' }}>
      {this.isMobile() && <p className="bold" style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '16px' }}>{title}</p>}
      <Avatar
        style={{ marginBottom: '13px' }}
        avatar
        spaced="right"
        src={this.props.entity.picture && this.props.entity.picture.uri}
        alt={this.props.entity.fullname}
      />
      <span>{this.props.entity.fullname} </span>
    </div>);
  }

  renderMediaButton = () =>
    <Button
      as={Dropzone}
      ref={(node) => { this.dropzone = node; }}
      onDrop={(accepted, rejected) => this.onFileDrop(accepted, rejected)}
      multiple
      accept={'image/png,image/jpeg,image/jpg,video/mp4,video/quicktime'}
      maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
      style={{ border: 'dashed 2px #e6e7e9',
        boxShadow: 'none',
        minWidth: 200,
        height: 180,
        display: 'table' }}
      basic
      disabled={this.state.values.medias.length >= MEDIA_LIMIT || this.state.values.medias.filter(media => media.loading).length}
    >
      <span
        style={{ display: 'table-cell', verticalAlign: 'middle'
        }}
      >
        {
          this.state.values.medias.length >= MEDIA_LIMIT ?
            __('Limit of %s media reached', MEDIA_LIMIT)
            :
            <Icon name="plus" style={{ fontSize: 20 }} />
        }
      </span>
    </Button>

  renderForm = () => {
    const { edit, cancelButton, submitButton } = this.props;
    const handleProps = this.handleProps();
    const { values } = this.state;

    const submitButtonIcon = null;

    const actionButtonsLeft = [<BetaButton
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton && cancelButton.text}
      disabled={handleProps.loading}
      onClick={() => this.setState({ values: { ...values, onCancel: true } }, () => this.handleCancel())}
    />];
    const actionButtonsRight = [<BetaButton
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton && submitButton.text}
      disabled={handleProps.submitButton.disabled || handleProps.loading}
      loading={handleProps.submitButton.loading || handleProps.loading}
      onClick={this.onSubmit}
    />];

    return (<Form
      submitButton={submitButton}
      cancelButton={cancelButton}
      id="MessageForm"
      onSubmit={this.onSubmit}
      actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
      actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
      style={{ padding: this.isMobile() ? '24px' : '0px' }}
      paddingHeader={this.isMobile()}
      hasDivider={this.isMobile()}
      header={this.isMobile() ? {
        title: '',
        onClose: () => this.setState({ values: { ...values, onCancel: true } }, () => this.handleCancel()),
        invertCloseButton: true,
        headerItem: <BetaButton
          data-action="submit"
          round
          text={submitButton.text}
          onClick={this.onSubmit}
          disabled={handleProps.submitButton.disabled || handleProps.loading}
          loading={handleProps.submitButton.loading || handleProps.loading}
          style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex', padding: '12px' }}
        /> } : null}
    >
      <div >
        {this.renderFromField()}
      </div>
      {
        edit ? null :
          this.isMobile() ? [
            <div style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex', overflowX: 'auto', paddingBottom: '10px' }}>
                {this.renderMedias()}
                {this.renderMediaButton()}
              </div>
            </div>
          ] :
            [
              <div style={{ marginBottom: '21px' }}>
                {this.renderManyRecipients()}
              </div>,
              <div style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex', overflowX: 'auto', paddingBottom: '10px' }}>
                  {this.renderMedias()}
                  {this.renderMediaButton()}
                </div>
              </div>
            ]
      }
      <div style={{ marginBottom: '21px' }}>
        <Form.FormTextArea
          style={{ resize: 'none' }}
          autoHeight
          labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
          placeholder={__('Write here the description...')}
          name="content"
          value={values.content}
          onChange={e =>
            this.onInputChange(e, { name: 'content', value: e.target.value })
          }
        />
      </div>
      {this.isMobile() && !edit &&
      <div style={{ marginBottom: '21px' }}>
        {this.renderManyRecipients()}
      </div>
      }
    </Form>);
  }

  render() {
    const { data, id, title } = this.props;

    if (data && ((data.loading && !data.node) || !data.node)) return <Modal loading />;

    return (
      <BetaModal
        id={id}
        size="small"
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        portalHeader={this.isMobile()}
        actions={false}
        header={this.isMobile() ? null : title}
        onClose={() => this.setState({ values: { ...this.state.values, onCancel: false } }, () => this.handleCancel())}
        invertCloseButton={this.isMobile()}
        content={
          this.renderForm()
        }
      />
    );
  }
}
