import { setData } from '../setData';

describe('setData', () => {
  it('should return an array with the right values', async () => {
    const result = await setData({
      nodes: [
        {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          mimetype: 'image/jpeg',
          uri: 'https://d391o0jis58pqh.cloudfront.net/w1280/abcdef.jpg',
        },
        {
          mimetype: 'image/jpeg',
          uri: 'https://images.classapp.com.br/w1280/ghijkl.jpg',
        },
      ],
    });

    expect(result).toEqual([
      {
        mimetype: 'image/jpeg',
        url: 'https://s3-us-west-2.amazonaws.com/abcdef.jpg',
      },
      {
        mimetype: 'image/jpeg',
        url: 'https://s3-us-west-2.amazonaws.com/ghijkl.jpg',
      },
    ]);
  });
});
