import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Header, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import { __ } from '../../i18n';

import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Page from '../../components/Page';

import EntityItem from '../Entity/Item';

import * as utils from '../../utils';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query MomentStatusQuery(
  $momentId: ID!,
  $limit: Int,
  $offset: Int
) {
    node(id: $momentId) @connection(key: "Message", filter: ["momentId"]) {
      ... on Message {
        id: dbId
        reactions (limit: $limit, offset: $offset) {
          totalCount
          nodes {
            id: dbId
            created
            user {
              fullname
            }
            entity {
              id: dbId
              organizationId
              ...EntityItemEntity
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
  ${EntityItem.fragments.entity}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
    momentId: ownProps.moment.id,
    limit: PAGE_SIZE,
    offset: 0
    }
    })
  })
@observer
export default class MomentStatus extends Page {
  render() {
    const { data, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { totalCount, nodes, pageInfo } = data.node.reactions;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    return (
      <Modal id="MomentStatus" open size="small" closeIcon="times close" onClose={() => this.props.store.appends.pop()}>
        <Modal.Header>{__('Who liked your moment')}</Modal.Header>
        <Modal.Content style={{ padding: 0 }}>
          <div style={{ padding: '24px', borderBottom: '1px solid #eeeeee' }}>
            <Header as="h2">
              <Icon
                name="heart"
                style={{ fontSize: 20, color: '#0080ff', fontWeight: 'bold' }}
              />
              <Header.Content>
                {totalCount}
              </Header.Content>
            </Header>
          </div>
          <div style={{ padding: '24px' }}>
            <Table celled basic style={{ backgroundColor: 'white' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell key={0}>{__('Entity')}</Table.HeaderCell>
                  <Table.HeaderCell key={1}>{__('User')}</Table.HeaderCell>
                  <Table.HeaderCell key={2}>{__('Date')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  nodes.map(reaction =>
                    <Table.Row key={reaction.id}>
                      <Table.Cell key="entity" textAlign="left">
                        <EntityItem
                          entity={filter(EntityItem.fragments.entity, reaction.entity)}
                          limit
                          to={store.currentEntity.type === 'ADMIN' ? `/organizations/${reaction.entity.organizationId}/entities?search=${reaction.entity.fullname}` : null}
                        />
                      </Table.Cell>
                      <Table.Cell key="user" textAlign="left">{reaction.user.fullname}</Table.Cell>
                      <Table.Cell key="date" textAlign="left">{utils.simpleDate(reaction.created, true, 'll - LT', lang).replace('-', '·')}</Table.Cell>
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
            <Pagination
              pagination={{
                graphql: true,
                hasNextPage: pageInfo && pageInfo.hasNextPage,
                loading: data.loading
              }}
              onLoadMore={() => this.loadMore('reactions')}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
