import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { List, Icon, Segment, Dropdown, Header } from 'semantic-ui-react';
import gql from 'graphql-tag';
import Slider from 'react-slick';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Avatar from '../../components/Avatar';
import ReactionItem from '../../components/ReactionItem';
import Responsive from '../../components/Responsive';

import MomentStatus from './Status';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import { momentsDownload, videoDownload, imageDownload } from './MomentDownloader';
import VideoPlayer from '../../components/VideoPlayer';

const styles = {
  item: {
    padding: '0px 0px 16px 0px'
  }
};

const settings = {
  dots: true,
  dotsClass: 'slick-dots slick-dots-clsp'
};

@inject('store')@observer
export default class MomentItem extends Responsive {
  static fragments = {
    moment: gql`
      fragment MomentItem on Message {
        id: dbId
        key
        created
        content
        type
        entity {
          id: dbId
          organizationId
          fullname
          picture {
            uri
            id: dbId
            key
          }
        }
        reaction (entityId: $entityId) {
          type
        }
        reactions {
          totalCount
        }
        medias {
          nodes {
            id: dbId
            mimetype
            uri (size:"w1280")
            origName
          }
        }
      }`,
    organizationMoment: gql`
        fragment MomentItemOrganization on Message {
          id: dbId
          key
          created
          content
          type
          deleted
          entity {
            id: dbId
            organizationId
            fullname
            picture {
              uri
              id: dbId
              key
            }
          }
          reactions {
            totalCount
          }
          medias {
            nodes {
              id: dbId
              mimetype
              uri (size:"w1280")
              origName
            }
          }
        }`
  }

  state = {
    downloading: false,
  };

  renderDropdownIcon = () => (this.state.downloading ? <Icon data-action="open-moment-options" name="spinner" loading style={{ color: '#9696a0' }} /> : <Icon data-action="open-moment-options" name="ellipsis h" style={{ color: '#9696a0' }} />)

  render() {
    const { moment, params, store } = this.props;
    const { entity, id, medias, created, type, deleted, reactions } = moment;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    const date = utils.simpleDate(created, true, null, lang);
    const baseLink = 'entity_id' in params ?
      `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;
    const isAdmin = (store.currentUser.isMaster || (params.organization_id && store.currentEntity.type === 'ADMIN') || (store.currentEntity.type === 'ADMIN' && store.currentEntity.organization.id === entity.organizationId));
    const canReact = store.currentEntity.id == params.entity_id;

    if (!medias.nodes.length) return null;

    const dropdownItems = [];

    if (type === 'SENT' && canReact && !params.organization_id && !deleted) {
      dropdownItems.push(<Dropdown.Item
        as={Link}
        to={`/entities/${params.entity_id}/moments/${moment.id}/edit`}
        data-action="edit"
      >
        {__('Edit Description')}
      </Dropdown.Item>);
    }

    if (((type === 'SENT' && canReact) || (params.organization_id && (store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('DELETE_MESSAGE')))))) && !deleted) {
      dropdownItems.push(<Dropdown.Item
        as={Link}
        to={baseLink + `/moments/${moment.id}/delete`}
        data-action="delete"
      >
        {__('Delete Moment')}
      </Dropdown.Item>);
    }

    if (dropdownItems.length) dropdownItems.push(<Dropdown.Divider />);

    if (medias.nodes.length === 1 && medias.nodes[0].mimetype.includes('video')) {
      dropdownItems.push(<Dropdown.Item
        onClick={() => videoDownload(medias.nodes[0], moment.key, (value) => { this.setState({ downloading: value }); }, (msg) => { this.props.store.snackbar = { active: true, message: msg, success: false }; })}
      >
        {__('Save Video')}
      </Dropdown.Item>);
    } else if (medias.nodes.length === 1 && !medias.nodes[0].mimetype.includes('video')) {
      dropdownItems.push(<Dropdown.Item
        onClick={() => imageDownload(medias.nodes[0], moment.key, (value) => { this.setState({ downloading: value }); }, (msg) => { this.props.store.snackbar = { active: true, message: msg, success: false }; }, this.props.store.app)}
      >
        {__('Save Image')}
      </Dropdown.Item>);
    } else {
      dropdownItems.push(<Dropdown.Item
        onClick={() => momentsDownload(medias, moment.key, (value) => { this.setState({ downloading: value }); }, (msg) => { this.props.store.snackbar = { active: true, message: msg, success: false }; }, this.props.store.app)}
      >
        {__('Save Moment')}
      </Dropdown.Item>);
    }

    return (<List.Item
      className={'momentItem'}
      style={styles.item}
      data-id={id}
      key={id}
    >
      <Segment.Group style={{ width: '100%' }}>
        <Segment>
          <Header as="h5" className="entityItem" style={{ display: 'inline-flex', alignItems: 'center' }} title={entity.fullname} data-id={entity.id}>
            <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} style={{ height: '2.5em' }} />
            <Header.Content>
              {
                entity.fullname ?
                  (
                    !isAdmin ?
                      <span>{entity.fullname}</span>
                      :
                      <Link className="bold defaultClspColor" to={`/entities/${entity.id}`}>{entity.fullname}</Link>
                  )
                  :
                  null
              }
              <Header.Subheader style={{ color: '#9696a0' }}>
                {date}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        <Segment style={{ padding: 0 }} className="clsp-square">
          {
            medias.nodes.length > 1 ?
              <Slider {...settings} className="clsp-square-content">
                {
                  medias.nodes.map((media, key) => (media.mimetype.includes('video') ?
                    <div className="clsp-video">
                      <VideoPlayer oldPlayer loop src={media.uri} name={media.origName} thumbnail={media.thumbnail} />
                    </div>
                    :
                    <div
                      key={key}
                      className="momentImage"
                      style={{
                        width: '100%',
                        background: 'url(' + media.uri + ')'
                      }}
                    />))
                }
              </Slider>
              :
              (medias.nodes[0].mimetype.includes('video') ?
                <div className="clsp-square-content" style={{ display: 'flex' }}>
                  <div className="clsp-video" style={{ width: '100%' }}>
                    <VideoPlayer oldPlayer loop src={medias.nodes[0].uri} name={medias.nodes[0].origName} thumbnail={medias.nodes[0].thumbnail} />
                  </div>
                </div>
                :
                <div
                  className="clsp-square-content"
                  style={{
                    background: 'url(' + medias.nodes[0].uri + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />)

          }
        </Segment>
        <Segment>
          <div className="horizontallySpacedItems">
            <Header as="h5" style={{ margin: 0 }}>
              <ReactionItem message={moment} canReact={canReact} params={params} />
              {
                !reactions.totalCount ?
                  null
                  :
                  <Header.Content
                    style={{ color: '#868e96' }}
                    onClick={(type === 'SENT' || params.organization_id) ? () => store.appends.push(<MomentStatus moment={moment} />) : undefined}
                    className={(type === 'SENT' || params.organization_id) ? 'pointer' : ''}
                  >
                    {reactions.totalCount > 1 ? __('%s likes', reactions.totalCount) : __('1 like')}
                  </Header.Content>
              }
            </Header>
            <Dropdown icon={null} trigger={this.renderDropdownIcon()}>
              <Dropdown.Menu style={this.isMobile() ? { right: 0, left: 'auto' } : null} className="bold">
                {dropdownItems}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {!!moment.content && <div className="content wordWrapped clsp-content" style={{ marginTop: 12 }}>
            {moment.content.split(/(#[A-Za-z0-9À-ÿ]+)/g).map((content) => {
              if (content.match(/#[A-Za-z0-9À-ÿ]+/g)) {
                return <Link to={baseLink + `/moments?search=${encodeURIComponent(content)}${deleted ? '&folder=DELETED' : ''}`}>{content}</Link>;
              }
              return content;
            })}
          </div>}
        </Segment>
      </Segment.Group>

    </List.Item>);
  }
}
