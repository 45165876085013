import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import MomentForm from './Form';

import { __ } from '../../i18n';

@withRouter @inject('store')
@graphql(gql`query MomentEditQuery ($momentId: ID!, $entityId: ID!) {
    moment: node(id: $momentId) @connection(key: "Message", filter: ["momentId"]) {
      ... on Message {
        id: dbId
        content
        medias {
          nodes {
            id: dbId
            filename
            type
          }
        }
      }
    }
    entity: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        type
        fullname
        picture {
          uri
          id: dbId
          key
        }
      }
    }
  }
`, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
      momentId: ownProps.params.moment_id,
      entityId: ownProps.params.entity_id
    }
  })
})
@graphql(gql`mutation updateMessage($updateMessageMutation: UpdateMessageInput!) {
  updateMessage(input: $updateMessageMutation) {
    clientMutationId
  }
}`, {
  name: 'updateMessage',
  options: {
    refetchQueries: ['EntityMomentsQuery', 'MomentEditQuery']
  }
})
@observer
export default class MomentEdit extends Controller {

  static fetchData({ store }) {
    store.app.title = __('Edit moment');
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (!this.state.onLeave) return __('Your moment was not shared! Are you sure you want to leave?');
    });
  }

  treatValues = (values) => {
    values.medias = this.props.data.moment.medias.nodes.map(media => ({
      filename: media.filename,
      type: media.type
    }));

    values.content = values.content.replace(new RegExp('<a href', 'g'), '<a target="_blank" rel="noreferrer noopener" href');

    return values;
  }

  request = (values) => {
    const { params, updateMessage, store, router } = this.props;

    values = this.treatValues(values);

    const props = {
      id: params.moment_id,
      content: values.content,
      medias: values.medias
    };

    return updateMessage({
      variables: {
        updateMessageMutation: {
          ...props
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('Moment saved successfully'), success: true };

      const baseLink = 'entity_id' in params ?
        `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true }, () => {
        router.push(baseLink + '/moments');
      });
    });
  }

  onCancel = () => this.props.store.appends.push(<Modal
    size="tiny"
    onClose={() => this.props.store.appends.pop()}
    header={__('Discard moment')}
    content={__('You have made changes to the moment, discard changes?')}
    actions={[
      <Button
        data-action="cancel"
        key={0}
        basic
        floated="left"
        content={__('Cancel')}
        onClick={() => this.props.store.appends.pop()}
      />,
      <Button
        data-action="discard-changes"
        key={2}
        negative
        content={__('Discard Changes')}
        onClick={() => {
          this.props.store.appends.pop();
          this.setState({ onLeave: true }, () => {
            this.props.router.goBack();
          });
        }}
      />
    ]}
  />)


  render() {
    const { data, params } = this.props;

    if ((data.loading && !(data.moment || data.entity)) || !data.moment || !data.entity) return <Modal loading />;

    const { moment, entity } = data;
    const { type, picture, fullname, groups } = entity;


    return (
      <Controller
        id="MomentEdit"
        edit
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MomentForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={values => this.onCancel(values)}
        submitButton={{
          text: __('Save'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        formProps={{
          entity: {
            id: params.entity_id,
            type,
            fullname,
            picture,
            groups
          },
          title: __('Edit moment'),
          id: 'MomentEdit'
        }}
        values={{ content: moment.content }}
        {...this.props}
      />
    );
  }
}
