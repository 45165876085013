import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import MomentForm from './Form';

import { __ } from '../../i18n';

@withRouter @inject('store')
@graphql(gql`query MomentAddQuery ($id: ID!) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        type
        fullname
        picture {
          uri
          id: dbId
          key
        }
        groups {
          totalCount
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.entity_id
    }
  })
})
@graphql(gql`mutation createMessage($createMessageMutation: CreateMessageInput!) {
  createMessage(input: $createMessageMutation) {
    clientMutationId
  }
}`, {
  name: 'createMessage',
  options: {
    refetchQueries: ['EntityMomentsQuery', 'MessageRecipientsQuery']
  }
})
@observer
export default class MomentAdd extends Controller {

  static fetchData({ store }) {
    store.app.title = __('Send moment');
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (!this.state.onLeave) return __('Your moment was not shared! Are you sure you want to leave?');
    });
  }

  treatValues = (values) => {
    if (values.groups.find(group => group === 'staff')) {
      values.types = 'STAFF';
      values.groups = values.groups.filter(group => group !== 'staff');
    }

    values.content = values.content.replace(new RegExp('<a href', 'g'), '<a target="_blank" rel="noreferrer noopener" href');

    return values;
  }

  request = (values) => {
    const { params, createMessage, store, router } = this.props;

    values = this.treatValues(values);

    const props = {
      moment: true,
      entityId: this.props.params.entity_id,
      content: values.content,
      medias: values.medias,
      recipients: {
        groupIds: values.groups,
        types: values.types
      }
    };

    return createMessage({
      variables: {
        createMessageMutation: {
          ...props
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('Moment was shared'), success: true };
      const baseLink = 'entity_id' in params ?
        `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true }, () => {
        router.push(baseLink + '/moments');
      });
    });
  }

  onCancel = () => this.props.store.appends.push(<Modal
    size="tiny"
    onClose={() => this.props.store.appends.pop()}
    header={__('Discard moment')}
    content={__('Are you sure you want to discard this moment?')}
    actions={[
      <Button
        data-action="cancel"
        key={0}
        basic
        floated="left"
        content={__('Cancel')}
        onClick={() => this.props.store.appends.pop()}
      />,
      <Button
        data-action="submit"
        key={2}
        negative
        content={__('Discard')}
        onClick={() => {
          this.props.store.appends.pop();
          this.setState({ onLeave: true }, () => {
            this.props.router.goBack();
          });
        }}
      />
    ]}
  />)

  render() {
    const { data, params } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { type, picture, fullname, groups } = data.node;

    return (
      <Controller
        id="MomentAdd"
        add
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MomentForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={values => this.onCancel(values)}
        submitButton={{
          text: __('Publish'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        formProps={{
          entity: {
            id: params.entity_id,
            type,
            fullname,
            picture,
            groups
          },
          title: __('New moment'),
          id: 'MomentAdd'
        }}
        {...this.props}
      />
    );
  }
}
