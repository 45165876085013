import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';
import * as utils from '../../utils';

@graphql(gql`mutation deleteMessage($deleteMessageMutation: DeleteMessageInput!) {
  deleteMessage(input: $deleteMessageMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['EntityMomentsQuery', 'OrganizationMomentsQuery']
  }
})
@inject('store') @observer
export default class MomentDelete extends Controller {

  state = {
    loading: false
  }

  request = () => {
    this.setState({ loading: true });

    this.props.mutate({
      variables: {
        deleteMessageMutation: {
          id: this.props.params.moment_id
        }
      }
    }).then(() => {
      this.setState({ loading: false });

      const { params, store, router } = this.props;
      store.snackbar = { active: true, message: __('Moment was deleted'), success: true };

      const baseLink = 'entity_id' in params ?
        `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      router.push(baseLink + '/moments');
    }).catch((err) => {
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  render() {
    return (
      <Controller
        id="MomentDelete"
        delete
        object={__('moment')}
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...this.props}
      />
    );
  }
}
